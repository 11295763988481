import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import BlockTitle from '../components/blockTitle'

const Section = ({ name, title, html }) => {
  const [style, setStyle] = useState({})

  useEffect(() => {
    let styleObj = {
      'h3': {
        fontSize: {
          xs: '1.25rem',
          md: '2rem'
        },
        fontWeight: 400,
        mt: 1,
        mb: 2
      },
      'h4': {
        fontSize: {
          xs: '1.125rem',
          md: '1.8rem'
        },
        fontWeight: 300,
        mt: {
          xs: 1,
          md: 2,
        },
        mb: 1
      },
      'p.absatz': {
        mt: 0,
        mb: 1
      },
      'a': {
        textDecoration: 'none',
        color: 'primary.main',
        '&:hover': {
          color: 'primary.dark'
        }
      },
      'ul': {
        pl: {xs: 2, md: 4},
        'li': {
          mb: 1
        }
      },
      '.gatsby-resp-image-image': {
        borderRadius: '0.333rem'
      },
      'div.pdf': {
        position: 'relative',
        mb: 3,
        'a': {
          display: 'block',
          pl: 3.5,
          '&::before': {
            position: 'absolute',
            top: '0.05rem',
            left: 0,
            content: 'url(/pdf.svg)',
            display: 'block',
            width: '20px'
          }
        }
      }
    }

    if (name === 'fotos') {
      styleObj = {
        ...styleObj,
        'div.flex-wrap': {
          display: 'flex',
          pt: 4,
          flexDirection: {
            xs: 'column',
            md: 'row'
          },
          '+ div.flex-wrap': {
            pt: {
              xs: 0,
              md: 'inherit'
            }
          }
        },
        'div.flex-item': {
          flex: 1,
          pr: {
            xs: 0,
            md: 1,
          },
          '&:last-child': {
            pl: {
              xs: 0,
              md: 1,
            },
          },
          '&.oneofthree': {
            flex: '0.9 1 auto'
          },
          '&.twoofthree': {
            flex: '1.1 1 auto'
          },
          '&.wide': {
            flex: '1.15 1 auto'
          },
          '&.narrow': {
            flex: '0.86 1 auto'
          },
          '.gatsby-resp-image-wrapper': {
            mb: 2,
          },
          '.gatsby-resp-image-image': {
            border: '1px solid',
            borderColor: 'primary.light',
            boxShadow: '0 2px 4px rgb(0 0 0 / 40%) !important',
            '&:hover': {
              opacity: '0.8 !important'
            }
          }

        }
      }
    }

    if (name === 'info') {
      styleObj = {
        ...styleObj,
        'p .gatsby-resp-image-wrapper': {
          float: 'left',
          width: {
            xs: '25vw',
            md: '200px'
          },
          mr: '1rem !important',
          mb: 1
        }
      }
    }

    if (name === 'diskographie') {
      styleObj = {
        ...styleObj,
        'p.abstandbottom': {
          pb: 4
        },
        'div.flex-wrap': {
          display: 'flex',
          mb: 8
        },
        'div.flex-item': {
          flex: 1,
          pr: 1,
          '&.oneoffive': {
            flex: {
              xs: '0.5 1 auto',
              md: '0.25 1 auto'
            },
            pr: {
              xs: 2,
              md: 6
            },
          },
          '&.fouroffive': {
            flex: {
              xs: '0.6 1 auto',
              md: '0.8 1 auto'
            },
          },
          '.gatsby-resp-image-image': {
            border: '1px solid',
            borderColor: 'primary.light',
            boxShadow: '0 2px 4px rgb(0 0 0 / 40%) !important',
            '&:hover': {
              opacity: '0.8 !important'
            }
          }
        }
      }
    }

    if (name === 'videos') {
      styleObj = {
        ...styleObj,
        'iframe': {
          maxWidth: '500px',
          display: 'block',
          pt: 4,
          mx: 'auto',
        }
      }
    }

    if (name === 'plakat') {
      styleObj = {
        ...styleObj,
        'div.plakat': {
          'a': {
            display: 'inline',
            width: '100%',
            maxWidth: '300px',
            '> span': {
              ml: {
                xs: 'auto',
                sm: '0 !important'
              }
            }
          }
        }
      }
    }

    if (name === 'logo') {
      styleObj = {
        ...styleObj,
        'div.logo': {
          display: 'flex',
          pt: 4,
          'a': {
            flex: '1 1 auto',
          },
          '.gatsby-resp-image-wrapper': {
            maxWidth: {
              xs: '150px !important',
              md: '300px !important'
            },
            height: 'auto',
            ml: {
              xs: 'auto',
              sm: '2rem !important'
            }
          },
          '.gatsby-resp-image-image': {
            boxShadow: 'none !important',
            backgroundColor: 'secondary.light',
            maxWidth: {
              xs: '150px',
              md: '300px'
            },
            height: 'auto'
          }
        }
      }
    }

    setStyle(styleObj)
  }, [name])

  return (
    <Box
      id={name}
      sx={{
        pt: 10
      }}
    >
      <BlockTitle title={title} />
      <Box
        dangerouslySetInnerHTML={{ __html: html }}
        sx={style}
      />
    </Box>
  )
}

export default Section